<template>
  <div>
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeLink: "dashboard",
    };
  },
  computed: {
    drawer1: {
      get() {
        return this.$store.state.dashboard.inviteDrawer1;
      },
      set(v) {
        this.$store.commit("setInviteDrawer1", v);
      },
    },
    drawer2: {
      get() {
        return this.$store.state.dashboard.inviteDrawer2;
      },
      set(v) {
        this.$store.commit("setInviteDrawer2", v);
      },
    },
  },
  watch: {
    showManageTeam() {
      if (this.showManageTeam) {
        this.showCreateTeam = false;
      }
    },
  },
  methods: {
    onClickDashboardLink() {
      this.activeLink = "dashboard";
      this.$router.push({ name: "get-started" }).catch(() => {});
    },
    openInviteDrawer() {
      this.inviteDrawer = true;
    },
    updateTags() {
      this.$nextTick(() => {
        this.emails.push(...this.search.split(","));
        this.$nextTick(() => {
          this.search = "";
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
#quick_create_app {
  overflow: hidden;
}
.quick_create_app {
  .offcanvas {
    right: 0;
    &.offcanvas-right {
      right: -900px;
      &.offcanvas-on {
        right: 0;
      }
    }
  }
}
</style>
